import React from "react";

import styles from "./social-media.module.scss";

import { FacebookButton } from "../../shared/facebook-button";

export const SocialMedia: React.FC = () => (
  <div className={styles.wrapper}>
    <FacebookButton href="https://www.facebook.com/baguetteriesimplyfood">
      Petit Café auf Facebook
    </FacebookButton>
    <FacebookButton href="https://www.facebook.com/Caf%C3%A9-Paris-417127952173100/">
      Kultur Café Paris auf Facebook
    </FacebookButton>
  </div>
);
