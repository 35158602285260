import React from "react";

import { Layout } from "../components/shared/layout";
import { Helmet } from "../components/shared/helmet";

import { ContactData } from "../components/kontakt/contact-data";
import { Address } from "../components/kontakt/address";
import { Map } from "../components/kontakt/map";
import { SocialMedia } from "../components/kontakt/social-media";

export default () => (
  <Layout>
    <Helmet title="Kontakt" />
    <ContactData />
    <Address />
    <SocialMedia />
    <Map />
  </Layout>
);
