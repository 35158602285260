import React from "react";

import styles from "./map.module.scss";

export const Map: React.FC = () => (
  <div className={styles.wrapper}>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9971.852581266563!2d6.5614357!3d51.3301977!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa06f915921a12ea0!2sPetit%20Caf%C3%A9!5e0!3m2!1sen!2sde!4v1604412195975!5m2!1sen!2sde"
      width="600"
      height="450"
      frameBorder={0}
      title="Google Maps"
    ></iframe>
  </div>
);
