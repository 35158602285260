import React from "react";

import styles from "./contact-data.module.scss";

const Record: React.FC<{ iconUrl: string }> = ({ children, iconUrl }) => (
  <div className={styles.record}>
    <div className={styles.recordIcon}>
      <img src={iconUrl} alt="Icon" draggable={false} />
    </div>
    <div className={styles.recordContent}>{children}</div>
  </div>
);

type ScheduleProps = {
  title: string;
  items: Array<{ day: string; time: string }>;
  extraInfo?: string;
};

const Schedule: React.FC<ScheduleProps> = ({
  children,
  title,
  items,
  extraInfo,
}) => (
  <div className={styles.schedule}>
    <div className={styles.scheduleTitle}>{title}</div>
    <div className={styles.scheduleText}>
      <table>
        <tbody>
          {items.map(({ day, time }, i) => (
            <tr key={i}>
              <td>
                <strong>{day}</strong>
              </td>
              <td>{time}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {extraInfo && <p className={styles.scheduleExtra}>{extraInfo}</p>}
    </div>
  </div>
);

export const ContactData: React.FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.introWrapper}>
      <div className={styles.innerIntroWrapper}>
        <div className={styles.title}>KONTAKT</div>
        <p>
          Haben Sie Fragen zum Petit Café oder Café Paris, oder möchten Sie
          einen Tisch reservieren, oder haben Sie einen Catering Wunsch? Rufen
          Sie uns einfach an oder schreiben Sie uns eine Email. Wir setzen uns
          schnellstmöglich mit Ihnen in Verbindung. Wir freuen uns auf Ihre
          Anfrage!
        </p>
      </div>
    </div>
    <div className={styles.recordWrapper}>
      <div className={styles.row}>
        <Record iconUrl={require("../../../images/icons/phone-alt.svg")}>
          <strong>0157 - 70339184</strong>
        </Record>
        <Record iconUrl={require("../../../images/icons/envelope.svg")}>
          <a href="mailto:krengelsys@t-online.de">krengelsys@t-online.de</a>
        </Record>
      </div>
      <Record iconUrl={require("../../../images/icons/clock.svg")}>
        <Schedule
          title="Petit Café"
          items={[
            {
              day: "Montag",
              time: "Geschlossen",
            },
            {
              day: "Dienstag - Sonntag",
              time: "12:00 - 18:00",
            },
          ]}
        />
        <Schedule
          title="Kultur Café Paris"
          items={[
            {
              day: "Montag - Freitag",
              time: "10:00 - 18:00",
            },
            {
              day: "Samstag",
              time: "9:30 - 16:00",
            },
            {
              day: "Sonntag",
              time: "Nur bei Reservierung",
            },
          ]}
          extraInfo="Bei Musik-, Konzert- und Theaterveranstaltungen: bis 23 Uhr"
        />
      </Record>
    </div>
  </div>
);
