import React from "react";

import { Section } from "../../shared/section";
import styles from "./address.module.scss";

export const Address: React.FC = () => (
  <Section>
    <h2>Anschrift</h2>
    <div className={styles.wrapper}>
      <div className={styles.wrapper}>
        <p>
          <strong>Petit Café</strong>
          <br />
          Marktstr. 58
          <br />
          47798 Krefeld
        </p>
        <p>
          <strong>Kultur Café Paris</strong>
          <br />
          Theaterplatz 2
          <br />
          47798 Krefeld
        </p>
      </div>
    </div>
  </Section>
);
